import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">

      <p className="p-footer">Carla Vila © - Tous droits réservés. </p>
    </div>
  );
};
export default Footer;
